import React, { useState, useEffect } from "react";
import Delete from "../../images/delete.png";
import EditIcon from "../../images/editing.png";

const MyDeal = ({ myDeals, User, onDealStatusChange, onDeleteDeal }) => {
  const [modal, setModal] = useState(false);
  const [editDeal, setEditDeal] = useState({});
  const [activeStatus, setActiveStatus] = useState({});
  const handleEdit = (deal) => {
    setEditDeal(deal);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setEditDeal(null);
  };
  const handleDelete = async (dealId) => {
    console.log(dealId);
    try {
      const confirmation = window.confirm(
        "Are you sure you want to delete this deal?"
      );
      if (!confirmation) {
        return;
      }
      await onDeleteDeal(dealId);
    } catch (error) {
      console.error("Error deleting deal:", error);
    }
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  // Filter deals based on the logged-in user's ID
  const filteredDeals = myDeals.filter((deal) => deal.user_id === User.user_id);
  useEffect(() => {
    if (myDeals && myDeals.length > 0) {
      const initialStatus = myDeals.reduce((acc, deal) => {
        acc[deal.deal_id] = true;
        return acc;
      }, {});
      setActiveStatus(initialStatus);
    }
  }, [myDeals]);

  if (!myDeals) {
    return null;
  }
  return (
    <>
      <div className="container mx-auto">
        {filteredDeals.length === 0 ? (
          <div className="flex items-center justify-center h-96 ">
            <p className="text-yellow-500 text-center text-2xl">
              No Deals Available
            </p>
          </div>
        ) : (
          <div className="container mx-auto">
            {filteredDeals.map((deal) => (
              <div
                key={deal.deal_id}
                className=" text-white rounded-md  grid mx-auto max-w-sm mt-5"
              >
                <div className="ride-ui mx-auto max-w-sm mb-4">
                  <div className="ride-imge">
                    {Array.isArray(deal.deal_media) &&
                      deal.deal_media.map((image, i) => (
                        <img
                          key={i}
                          src={image.file}
                          alt={`Deal Image ${i}`}
                          className="h-48 w-[400px] object-cover"
                        />
                      ))}
                  </div>
                  <div className="callender-info-box flex justify-center items-center bg-black h-12">
                    <h3 className="text-black font-bold">{deal.deal_title}</h3>
                  </div>
                  <div className="ride-info-box justify-center items-center bg-white rounded-2xl">
                    <div className="grid grid-cols-2">
                      <div className="p-2">
                        <h3 className="text-black">Regular Price</h3>
                        <p className="text-black">
                          <del className="text-red-500">
                            {deal.deal_regular_price}{" "}
                          </del>
                        </p>
                      </div>
                      <div className="p-2">
                        <h3 className="text-black">Sale Price</h3>
                        <p className="text-black">{deal.deal_sale_price}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-1">
                      <h2 className="text-sm mt-4 text-black font-medium">
                        {deal.deal_details}
                      </h2>
                    </div>
                  </div>

                  <div className="rid-action-box mt-4 text-center">
                    <button
                      className="mx-auto"
                      onClick={() => handleEdit(deal)}
                    >
                      <img src={EditIcon} alt="WhatsApp icon" />
                      <span>Edit deal</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Modal */}
        {modal && (
          <div className="modal">
            <div onClick={closeModal} className="overlay"></div>
            <button
              className="close-modal bg-red-600 hover:bg-black"
              onClick={closeModal}
            >
              CLOSE
            </button>
            <div className="modal-content">
              {/* Edit content here */}
              <div className="flex flex-col items-center justify-between w-full">
                <div className="flex items-center justify-between w-full">
                  <div className="mb-2 mr-4">
                    <label className="inline-flex items-center cursor-pointer mt-5">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={editDeal.deal_isactive}
                        onChange={() => onDealStatusChange(editDeal)}
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400"></div>
                      <span className="ms-3 text-sm font-medium text-white">
                        {activeStatus[editDeal.deal_id] ? "Active" : "Inactive"}
                      </span>
                    </label>
                  </div>

                  <button
                    className="bg-red-600 hover:bg-black text-white font-semibold py-2 px-4 rounded w-45"
                    onClick={() => handleDelete(editDeal.deal_id)}
                  >
                    <img src={Delete} alt="Delete" className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyDeal;
