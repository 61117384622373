import React, { useState } from "react";
import ReactModal from "react-modal";
import { getCurrentUser } from "../../services/auth";

ReactModal.setAppElement("#root");

const RatingModal = ({
  isOpen,
  onRequestClose,
  rating,
  setRating,
  userId,
  onSubmit,
}) => {
  const { user_id, user_name, phone_number } = getCurrentUser();
  const user = { user_id, user_name, phone_number };
  const [hoverRating, setHoverRating] = useState(0);

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleSubmit = async () => {
    const ratingData = {
      user_id: userId,
      rating_by_user: user,
      rating_stars: rating,
    };
    await onSubmit(ratingData);
    onRequestClose();
  };

  const handleMouseEnter = (rate) => {
    setHoverRating(rate);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75"
    >
      <div className="text-center">
        <h2 className="text-xl font-bold mb-4">Rate the Ride</h2>
        <div className="flex justify-center mb-4">
          {[...Array(5)].map((star, index) => (
            <button
              key={index}
              className={`text-4xl cursor-pointer bg-white ${
                (hoverRating || rating) > index
                  ? "text-yellow-500"
                  : "text-gray-300"
              }`}
              onClick={() => handleRating(index + 1)}
              onMouseEnter={() => handleMouseEnter(index + 1)}
              onMouseLeave={handleMouseLeave}
            >
              ★
            </button>
          ))}
        </div>
        <button
          onClick={handleSubmit}
          className="mt-4 bg-black text-white py-2 px-4 rounded-lg mr-2"
        >
          Submit
        </button>
        <button
          onClick={onRequestClose}
          className="mt-2 bg-gray-300 text-black py-2 px-4 rounded-lg"
        >
          Close
        </button>
      </div>
    </ReactModal>
  );
};

export default RatingModal;
