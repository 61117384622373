import React, { useState, useEffect } from 'react';
import { Home, LogOut, Plus, Box } from 'react-feather';
import AddNewRide from '../Pages/Rider/AddNewRide';
import Logo from '../images/Logo.png';
import MyDeal from '../images/deal.png';
import Logout from '../images/logout.png';
import Deals from '../images/add-to-cart.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './Header.css';
import AddDeal from '../Pages/Deals/AddDeal';

const Header = ({
	showAddRideButton,
	showHomeButton,
	onLogout,
	onAddRide,
	onAddDeal,
	onViewChange,
	onViewAllDeals,
	onViewMyDeals,
	showAddDealButton,
	showMyDealButton,
}) => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showUserView, setShowUserView] = useState(false);
	const [showDealModal, setShowDealModal] = useState(false);

	useEffect(() => {
		Aos.init({ duration: 2000 });
	});

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};
	const toggleDealModal = () => {
		setShowDealModal(!showDealModal);
	};

	const toggleUserView = () => {
		setShowUserView(!showUserView);
	};

	const handleHomeClick = () => {
		onViewChange('PassengerHome');
	};
	const handleMyDeal = () => {
		onViewChange('MyDeal');
	};
	return (
		<header>
			<nav
				style={{ backgroundColor: '#000', borderColor: '#cbd5e0' }}
				className="border-gray-200 dark:bg-gray-900"
			>
				<div className="max-w-screen-xl flex flex-wrap  items-center justify-between mx-auto p-4">
					{/* <a
						href=""
						className="flex items-center space-x-3 rtl:space-x-reverse"
					>
						<img
							src={Logo}
							alt="TOGO Logo"
							className="w-40"
							data-aos="flip-right"
							data-aos-once="false"
						/>
					</a> */}

					<div className="flex md:order-2">
						{showHomeButton && (
							<button
								className="flex items-center bg-theme-yellow mx-2 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
								onClick={handleHomeClick}
							>
								<span className="">
									<Home
										size={15}
										color="white"
									/>
								</span>
							</button>
						)}
						{showMyDealButton && (
							<button
								onClick={handleMyDeal}
								className="bg-blue-400 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
							>
								{/* <span className="mr-2">
									<img
										src={MyDeal}
										alt="MyDeal"
										width={15}
									/>
								</span> */}
								<span className="">
									<img
										src={Deals}
										alt="Deals"
										style={{ width: 15, height: 15 }}
									/>
								</span>
							</button>
						)}
						{showAddRideButton && (
							<button
								onClick={toggleModal}
								className=" bg-theme-yellow hover:bg-yellow-400 text- font-bold py-2 px-4 rounded"
							>
								<span className="">
									<Plus
										size={15}
										color="white"
									/>
								</span>
							</button>
						)}
						{showAddDealButton && (
							<button
								onClick={toggleDealModal}
								className=" bg-blue-400 hover:bg-yellow-400 text- font-bold py-2 px-4 rounded"
							>
								<span className="">
									<Plus
										size={15}
										color="white"
									/>
								</span>
							</button>
						)}

						{/* <button
							className=" flex items-center bg-red-600 mx-2 hover:bg-red-900 text-white font-semibold py-2 px-4 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
							onClick={onLogout}
						>
							<span className="">
								<LogOut
									size={15}
									color="white"
								/>
							</span>
						</button> */}
					</div>
					<button
						className=" flex items-center bg-red-600 mx-2 hover:bg-red-900 text-white font-semibold py-2 px-4 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
						onClick={onLogout}
					>
						<span className="">
							<img
								src={Logout}
								alt="Logout"
								style={{ width: 15, height: 15 }}
							/>
						</span>
					</button>
				</div>
			</nav>
			{showModal && (
				<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
					<AddNewRide
						showModal={showModal}
						toggleModal={toggleModal}
						onAddRide={onAddRide}
					/>
				</div>
			)}{' '}
			{showDealModal && (
				<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
					<AddDeal
						showDealModal={showDealModal}
						toggleDealModal={toggleDealModal}
						onAddDeal={onAddDeal}
					/>
				</div>
			)}{' '}
		</header>
	);
};

export default Header;
