import React, { useState } from "react";
import RideInfo from "../../images/ride-detail.png";
import ImageUpload from "../../images/upload.png";
import Finish from "../../images/success-mark.png";
import Success from "../../images/success.gif";
import makes from "../../Data/Makes.json";
import cityData from "../../Data/city.json";
import modelYears from "../../Data/years.json";
import Loader from "react-js-loader";
import MediaFile from "../../Components/MediaUploads";
import Done from "../../images/Complete.gif";

const makeOptions = makes.makes;
const cityOptions = cityData.cities;
const yearOptions = modelYears.years;

const AddNewRide = ({ showModal, toggleModal, onAddRide }) => {
  const [screen, setScreen] = useState(1);
  const [loading, setLoading] = useState(false);
  const [Ride, setRide] = useState({
    user_id: "",
    ride_datetime: "",
    ride_source: "",
    ride_destination: "",
    ride_seats: "",
    ride_price: "",
    ride_car_make: "",
    ride_car_mode: "",
  });
  const handleNext = () => {
    setScreen(screen + 1);
  };
  const handleBack = () => {
    setScreen(screen - 1);
  };
  const onItemChange = (name, value) => {
    setRide({ ...Ride, [name]: value });
  };
  const handleAddRide = async () => {
    setLoading(true);
    const ride = { ride_status: true, ...Ride };
    await onAddRide(ride);
    setLoading(false);
    setScreen(3); // Move to the congratulation screen
  };
  const handleFilesUpload = (files) => {
    const ride_media = [...files];
    setRide({ ...Ride, ride_media });
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 ">
          <div className="bg-white rounded-lg w-96 relative h-[640px]">
            {loading && (
              <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
                <Loader
                  type="spinner-circle"
                  bgColor={"#000"}
                  title={"Loading..."}
                  size={100}
                />
              </div>
            )}
            <button
              onClick={toggleModal}
              className="absolute top-0 right-0 mt-2 mr-2 text-red-600 bg-transparent hover:bg-transparent p-0 z-10"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="flex justify-between items-center p-8 relative">
              <div className="flex-1 text-center text-black relative">
                <div
                  className={`w-12 h-12 mx-auto rounded-full flex items-center justify-center ${
                    screen > 1
                      ? "bg-green-600"
                      : screen === 1
                      ? "bg-yellow-500"
                      : "bg-gray-400"
                  }`}
                >
                  <img src={RideInfo} alt="Deal-Detail" width={18} />
                </div>
                <p>Ride</p>
              </div>
              <div className="flex-1 relative bottom-2.5">
                <div
                  className={`absolute top-1/2 left-0 w-full h-1 transform -translate-y-1/2 ${
                    screen > 1 ? "bg-green-600" : "bg-gray-400"
                  }`}
                />
              </div>
              <div className="flex-1 text-center text-black relative">
                <div
                  className={`w-12 h-12 mx-auto rounded-full flex items-center justify-center ${
                    screen > 2
                      ? "bg-green-600"
                      : screen === 2
                      ? "bg-yellow-500"
                      : "bg-gray-400"
                  }`}
                >
                  <img src={ImageUpload} alt="ImageUpload" width={18} />
                </div>
                <p>Image</p>
              </div>
              <div className="flex-1 relative bottom-2.5">
                <div
                  className={`absolute top-1/2 left-0 w-full h-1 transform -translate-y-1/2 ${
                    screen > 2 ? "bg-green-600" : "bg-gray-400"
                  }`}
                />
              </div>
              <div className="flex-1 text-center text-black relative">
                <div
                  className={`w-12 h-12 mx-auto rounded-full flex items-center justify-center ${
                    screen === 3 ? "bg-green-600" : "bg-gray-400"
                  }`}
                >
                  <img src={Finish} alt="Finish" width={18} />
                </div>
                <p>Done</p>
              </div>
            </div>

            <div className="border mb-10"></div>
            {screen === 1 && (
              <>
                {/* <div className="gap-4 mb-4"></div> */}
                <div className="grid grid-cols-1 gap-4 mb-4 ml-8 mr-8">
                  <input
                    type="datetime-local"
                    id="date-time"
                    name="ride_datetime"
                    onChange={(e) =>
                      onItemChange(e.target.name, e.target.value)
                    }
                    value={Ride.ride_datetime}
                    className="border border-gray-300 p-2 rounded-md flex-grow w-full"
                  />
                  <select
                    className="border border-gray-300 p-2 rounded-md"
                    onChange={(e) =>
                      onItemChange(e.target.name, e.target.value)
                    }
                    value={Ride.ride_source}
                    name="ride_source"
                  >
                    <option value="">Select Source</option>
                    {cityOptions.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                  <select
                    onChange={(e) =>
                      onItemChange(e.target.name, e.target.value)
                    }
                    value={Ride.ride_destination}
                    className="border border-gray-300 p-2 rounded-md"
                    name="ride_destination"
                  >
                    <option value="">Select Destination</option>
                    {cityOptions.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                  <div className="flex gap-3">
                    <input
                      type="number"
                      placeholder="No. of Seats"
                      className="w-1/2 border border-gray-300 p-2 rounded-md placeholder-black"
                      onChange={(e) =>
                        onItemChange(e.target.name, e.target.value)
                      }
                      value={Ride.ride_seats}
                      name="ride_seats"
                    />
                    <input
                      type="number"
                      placeholder="Price per Seat"
                      className="w-1/2 border border-gray-300 p-2 rounded-md placeholder-black"
                      onChange={(e) =>
                        onItemChange(e.target.name, e.target.value)
                      }
                      value={Ride.ride_price}
                      name="ride_price"
                    />
                  </div>
                  <select
                    className="border border-gray-300 p-2 rounded-md"
                    onChange={(e) =>
                      onItemChange(e.target.name, e.target.value)
                    }
                    name="ride_car_make"
                    value={Ride.ride_car_make}
                  >
                    <option value="">Select Car Makes</option>
                    {makeOptions.map((make, index) => (
                      <option key={index} value={make}>
                        {make}
                      </option>
                    ))}
                  </select>
                  <select
                    className="border border-gray-300 p-2 rounded-md"
                    onChange={(e) =>
                      onItemChange(e.target.name, e.target.value)
                    }
                    value={Ride.ride_car_mode}
                    name="ride_car_mode"
                  >
                    <option value="">Select Car Model</option>
                    {yearOptions.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="border mt-10 mb-8"></div>
                <button
                  className="bg-theme-yellow text-black font-bold py-2 px-4 rounded flex items-center float-right mr-8 hover:bg-black hover:text-white"
                  onClick={handleNext}
                >
                  Next
                </button>
              </>
            )}
            {screen === 2 && (
              <>
                <MediaFile
                  onFilesUploaded={handleFilesUpload}
                  text="Select Your Car Photo"
                />
                <div className="absolute bottom-[20px] left-0 right-0">
                  <div className="border mt-10 mb-10"></div>
                  <div className="flex justify-between ml-8 mr-8">
                    <button
                      className="bg-theme-yellow text-black font-bold py-2 px-4 rounded flex items-center my-5"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                    <button
                      onClick={handleAddRide}
                      className="bg-theme-yellow text-black font-bold py-2 px-4 rounded flex items-center my-5 ml-1 hover:bg-black hover:text-white"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
            <div
              className={`transition-opacity duration-500 ${
                screen !== 3 ? "opacity-0 absolute" : "opacity-100"
              }`}
            >
              {screen === 3 && (
                <>
                  <div className="text-center">
                    <img
                      src={Done}
                      alt="Done"
                      style={{ width: "150px", height: "150px" }}
                      className="mx-auto mb-4"
                    />
                    <h3 className="text-lg font-bold mb-4">Congratulations!</h3>
                    <p className="mb-4">
                      Your ride has been successfully added.
                    </p>
                    <button
                      className="bg-theme-yellow text-black font-bold py-2 px-4 rounded hover:bg-black hover:text-white"
                      onClick={toggleModal}
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewRide;
