import axios from "axios";
import data from "./config";
import { getCurrentUser } from "./auth";
const User = getCurrentUser();
const { api_url } = data;
// New axio interceptor syntax from
// source: https://stackoverflow.com/questions/68714143/how-can-i-use-axios-interceptors-to-add-some-headers-to-responses
axios.interceptors.request.use(
  (config) => {
    // console.log(config.url);
    if (
      config.url !==
      "https://qrxc1kq19k.execute-api.us-east-1.amazonaws.com/dev/upload-images"
    )
      config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export const DoLogin = async (login_info) => {
  try {
    const { data } = await axios.post(`${api_url}/login`, login_info);
    return data;
  } catch (e) {
    throw e;
  }
};

export const DoSignup = async (signup_data) => {
  try {
    const { data } = await axios.post(`${api_url}/get-code`, signup_data);
    return data;
  } catch (e) {
    throw e;
  }
};

export const verifyPinCode = async (phone_number, pin_code) => {
  try {
    const requestData = {
      phone_number: phone_number,
      pin_code: pin_code,
    };
    // console.log('Request Data:', requestData);
    const response = await axios.post(`${api_url}/verify`, requestData);
    return response.data.user_info.data;
  } catch (error) {
    console.error("Error verifying pin code:", error);
    throw error;
  }
};

export const getData = async (pk, sk, begin_with) => {
  try {
    const data = { pk, sk, begin_with };
    // Make the GET request to fetch categories
    const response = await axios.post(`${api_url}/get-data`, data);
    // Return the categories from the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error fetching data:", error);
    throw error;
  }
};
// Modify saveData function to accept only item_data parameter
export const saveData = async (item_data) => {
  try {
    // Make the POST request to save data
    const response = await axios.post(`${api_url}/rides`, item_data);
    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error saving data:", error);
    throw error;
  }
};
export const getRides = async (user_id = null) => {
  try {
    // Make the GET request to fetch rides for the specified user_id
    let response = {};
    if (user_id) {
      response = await axios.get(`${api_url}/rides/${user_id}`);
    } else {
      response = await axios.get(`${api_url}/rides`);
    }
    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error fetching rides:", error);
    throw error;
  }
};

export const deleteRide = async (ride_id) => {
  try {
    // Define the request body
    const requestData = {
      user_id: User.user_id, // Assuming User is defined and contains the user ID
      ride_id: ride_id,
    };

    // Make the DELETE request to delete the specified ride
    const response = await axios.delete(`${api_url}/rides`, {
      data: requestData,
    });
    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error deleting ride:", error);
    throw error;
  }
};

export const deleteDeal = async (deal_id) => {
  try {
    // Define the request body
    const requestData = {
      user_id: User.user_id, // Assuming User is defined and contains the user ID
      deal_id: deal_id,
    };

    // Make the DELETE request to delete the specified ride
    const response = await axios.delete(`${api_url}/deals`, {
      data: requestData,
    });
    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error deleting deal:", error);
    throw error;
  }
};

//DEALS POST DATA
export const saveDeal = async (dealData) => {
  try {
    // Make the POST request to save data
    const response = await axios.post(`${api_url}/deals`, dealData);
    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error saving data:", error);

    throw error;
  }
};

export const getDeals = async (user_id = null) => {
  try {
    // Make the GET request to fetch rides for the specified user_id
    let response = {};
    if (user_id) {
      response = await axios.get(`${api_url}/deals/${user_id}`);
    } else {
      response = await axios.get(`${api_url}/deals`);
    }
    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error fetching deals:", error);
    throw error;
  }
};
export const postRating = async (ratingData) => {
  try {
    const response = await axios.post(`${api_url}/ratings`, ratingData);
    return response.data;
  } catch (error) {
    console.error("Error posting rating:", error);
    throw error;
  }
};
export const getRating = async (user_id) => {
  try {
    const response = await axios.get(`${api_url}/ratings/${user_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching ratings:", error);
    throw error;
  }
};
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
