import React, { useState, useEffect } from 'react';
import '../Rider/Rider.css';
import Logo from '../../images/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Login = ({ onViewChange, onLogin }) => {
	useEffect(() => {
		Aos.init({
			// duration: 2000
		});
	});

	const [loginInfo, setLoginInfo] = useState({
		phone_number: '',
		pin_code: '',
	});
	const [showPassword, setShowPassword] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setLoginInfo((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleSignUpClick = () => {
		onViewChange('Register');
	};

	return (
		<section className="bg-theme-black">
			<div className="flex flex-col items-center justify-center h-screen px-6 py-8 mx-auto lg:py-0">
				<div className="w-full bg-black rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<div>
							<a
								href="#"
								className="flex items-center justify-center mb-6 text-2xl font-semibold "
							>
								<img
									src={Logo}
									alt="TOGO Logo"
									className="w-40 fade-left"
									data-aos="flip-right"
									data-aos-duration="2000"
								/>
							</a>
						</div>
						<h1
							className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl dark:text-white"
							data-aos="slide-left"
							data-aos-duration="1000"
						>
							Togo Online Store
						</h1>

						<div className="mb-6 mt-4">
							<input
								type="number"
								name="phone_number"
								value={loginInfo.phone_number}
								onChange={handleChange}
								className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-black placeholder-white"
								placeholder="Phone Number"
							/>

							<div className="relative">
								<input
									type={showPassword ? 'text' : 'password'}
									name="pin_code"
									value={loginInfo.pin_code}
									onChange={handleChange}
									className="shadow appearance-none border mt-5 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-black placeholder-white pr-10"
									placeholder="Password"
								/>
								<button
									type="button"
									className="absolute inset-y-6 right-0 flex items-center p-4 mr-1 hover:bg-black "
									onClick={togglePasswordVisibility}
								>
									<FontAwesomeIcon
										icon={showPassword ? faEyeSlash : faEye}
										className="text-white hover:text-gray-500 cursor-pointer"
									/>
								</button>
							</div>
						</div>

						<div className="flex items-center justify-between">
							<div className="flex items-start">
								<div className="flex items-center h-5">
									<input
										id="remember"
										aria-describedby="remember"
										type="checkbox"
										className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
									/>
								</div>
								<div className="ml-3 text-sm">
									<label
										htmlFor="remember"
										className="text-gray-500 dark:text-gray-300"
									>
										Remember me
									</label>
								</div>
							</div>
							<a
								href="#"
								className="text-sm font-medium text-theme-yellow hover:underline dark:text-primary-500"
							>
								Forgot password?
							</a>
						</div>
						<button
							onClick={() => onLogin(loginInfo)}
							className="bg-theme-yellow hover:bg-white hover:text-black text-black font-bold py-2 px-4 rounded"
						>
							Sign in
						</button>
						<p className="text-sm font-light text-gray-500 dark:text-gray-400">
							Don’t have an account yet?{' '}
							<button
								type="button"
								onClick={handleSignUpClick}
								className="font-medium text-green-600 hover:underline dark:text-primary-500"
							>
								Sign up
							</button>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Login;
