import React, { useState } from "react";

const Verify = ({ onVerify }) => {
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const handleChange = (index, value) => {
    // If the value is empty, remove the character at the current index
    if (value === "") {
      const updatedCode = [...verificationCode];
      updatedCode[index] = ""; // Remove the character
      setVerificationCode(updatedCode);
      // Move focus to the previous input field if backspace is pressed
      if (index > 0) {
        const prevInput = document.getElementById(`input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    } else if (/^\d$/.test(value)) {
      // If a digit is entered, update the verification code
      const updatedCode = [...verificationCode];
      updatedCode[index] = value;
      setVerificationCode(updatedCode);
      // Move focus to the next input field if a digit is entered
      if (value && index < 3) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleVerify = () => {
    // Pass the verification code back to the parent component
    onVerify(verificationCode);
  };

  return (
    <>
      <div className="relative flex flex-col justify-center overflow-hidden m-5 ">
        <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
          <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
            <div className="flex flex-col items-center justify-center text-center space-y-2">
              <div className="font-semibold text-3xl">
                <p>Code Verification</p>
              </div>
              <div className="flex flex-row text-sm font-medium text-gray-400">
                <p>We have sent a code to your Number</p>
              </div>
            </div>

            <div>
              <form action="" method="post">
                <div className="flex flex-col space-y-16">
                  <div className="flex justify-center">
                    <div className="flex flex-row items-center space-x-4">
                      {verificationCode.map((code, index) => (
                        <div key={index} className="w-9 h-12">
                          <input
                            id={`input-${index}`}
                            className="w-full h-full text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                            type="text"
                            value={code}
                            onChange={(e) =>
                              handleChange(index, e.target.value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </form>
              <div className="flex flex-col space-y-5 mt-5">
                <div>
                  <button
                    className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-theme-yellow border-none text-white text-sm shadow-sm bold"
                    onClick={handleVerify}
                  >
                    Verify Account
                  </button>
                </div>

                <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                  <p>Didn't receive code?</p>{" "}
                  <a
                    className="flex flex-row items-center text-theme-yellow"
                    href="http://"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Resend
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
