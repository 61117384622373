import React, { useState } from 'react';
import MediaFile from '../../Components/MediaUploads';
import Loader from 'react-js-loader';
import DealInfo from '../../images/food-deal.png';
import ImageUpload from '../../images/upload.png';
import Finish from '../../images/success-mark.png';
import Success from '../../images/success.gif';
import Done from '../../images/Complete.gif';
import MyDeal from './MyDeal';

const AddDeal = ({ showDealModal, toggleDealModal, onAddDeal }) => {
	const [screen, setScreen] = useState(1);
	const [loading, setLoading] = useState(false);
	const [Deal, setDeal] = useState({
		user_id: '',
		deal_title: '',
		deal_details: '',
		deal_regular_price: '',
		deal_sale_price: '',
		deal_media: '',
	});

	const handleNext = () => {
		setScreen(screen + 1);
	};

	const handleBack = () => {
		setScreen(screen - 1);
	};

	const onItemChange = (name, value) => {
		setDeal({ ...Deal, [name]: value });
	};

	const handleAddDeal = async () => {
		setLoading(true);
		const deal = { deal_isactive: true, ...Deal };
		await onAddDeal(deal);
		setLoading(false);
		setScreen(3); // Move to the success screen
	};

	const handleFilesUpload = (files) => {
		setDeal({ ...Deal, deal_media: files });
	};

	return (
		<>
			{showDealModal && (
				<div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
					<div className="bg-white rounded-lg w-96 relative shadow-lg add-deal-height h-[535px]">
						{loading && (
							<div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 ">
								<Loader
									type="spinner-circle"
									bgColor={'#000'}
									title={'Loading...'}
									size={100}
								/>
							</div>
						)}
						<button
							onClick={toggleDealModal}
							className="absolute top-0 right-0 mt-2 mr-2 text-red-600 focus:outline-none p-0 bg-transparent hover:bg-transparent z-10"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>

						<div className="flex justify-between items-center p-8 relative">
							<div className="flex-1 text-center text-black relative">
								<div
									className={`w-12 h-12 mx-auto rounded-full flex items-center justify-center ${
										screen > 1 ? 'bg-green-600' : screen === 1 ? 'bg-yellow-500' : 'bg-gray-400'
									}`}
								>
									<img
										src={DealInfo}
										alt="Deal-Detail"
										width={18}
									/>
								</div>
								<p>Deal</p>
							</div>
							<div className="flex-1 relative bottom-2.5">
								<div
									className={`absolute top-1/2 left-0 w-full h-1 transform -translate-y-1/2 ${
										screen > 1 ? 'bg-green-600' : 'bg-gray-400'
									}`}
								/>
							</div>
							<div className="flex-1 text-center text-black relative">
								<div
									className={`w-12 h-12 mx-auto rounded-full flex items-center justify-center ${
										screen > 2 ? 'bg-green-600' : screen === 2 ? 'bg-yellow-500' : 'bg-gray-400'
									}`}
								>
									<img
										src={ImageUpload}
										alt="ImageUpload"
										width={18}
									/>
								</div>
								<p>Image</p>
							</div>
							<div className="flex-1 relative bottom-2.5">
								<div
									className={`absolute top-1/2 left-0 w-full h-1 transform -translate-y-1/2 ${
										screen > 2 ? 'bg-green-600' : 'bg-gray-400'
									}`}
								/>
							</div>
							<div className="flex-1 text-center text-black relative">
								<div
									className={`w-12 h-12 mx-auto rounded-full flex items-center justify-center ${
										screen === 3 ? 'bg-green-600' : 'bg-gray-400'
									}`}
								>
									<img
										src={Finish}
										alt="Finish"
										width={18}
									/>
								</div>
								<p>Done</p>
							</div>
						</div>

						<div className="border mb-10"></div>
						{screen === 1 && (
							<>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleNext();
									}}
								>
									<div className="gap-4 mb-4 pl-8 pr-8">
										<input
											type="text"
											placeholder="Deal Title"
											className="border border-gray-300 p-2 rounded-md flex-grow w-full"
											onChange={(e) => onItemChange(e.target.name, e.target.value)}
											value={Deal.deal_title}
											name="deal_title"
											maxLength={25}
											required
										/>
										<textarea
											placeholder="Deal Detail"
											className="border border-gray-300 p-2 rounded-md flex-grow w-full my-4"
											required
											onChange={(e) => onItemChange(e.target.name, e.target.value)}
											value={Deal.deal_details}
											name="deal_details"
											rows="4"
											maxLength={100}
										/>
										<div className="flex gap-3">
											<input
												type="number"
												placeholder="Regular Price"
												className="w-1/2 border border-gray-300 p-2 rounded-md placeholder-black"
												required
												onChange={(e) => onItemChange(e.target.name, e.target.value)}
												value={Deal.deal_regular_price}
												name="deal_regular_price"
											/>
											<input
												type="number"
												placeholder="Sale Price"
												className="w-1/2 border border-gray-300 p-2 rounded-md placeholder-black"
												required
												onChange={(e) => onItemChange(e.target.name, e.target.value)}
												value={Deal.deal_sale_price}
												name="deal_sale_price"
											/>
										</div>
									</div>
									<div className="border mt-10 mb-6"></div>
									<button
										type="submit"
										className="bg-theme-yellow text-black font-bold py-2 px-4 rounded flex items-center float-right mr-8 hover:bg-black hover:text-white"
										// onClick={handleNext}
									>
										Next
									</button>
								</form>
							</>
						)}

						{screen === 2 && (
							<>
								<MediaFile
									onFilesUploaded={handleFilesUpload}
									text="Select Your Deal Photo"
								/>
								<div className="absolute bottom-[20px] left-0 right-0">
									<div className="border mt-[50px] mb-6"></div>
									<div className="flex justify-between mt-4 ml-8 mr-8">
										<button
											className="bg-theme-yellow text-black font-bold py-2 px-4 rounded flex items-center"
											onClick={handleBack}
										>
											Back
										</button>
										<button
											onClick={handleAddDeal}
											className="bg-theme-yellow text-black font-bold py-2 px-4 rounded flex items-center hover:bg-black hover:text-white"
										>
											Submit
										</button>
									</div>
								</div>
							</>
						)}
						{screen === 3 && (
							<div className="text-center">
								<img
									src={Done}
									alt="Done"
									className="mx-auto mb-4"
									style={{ width: '150px', height: '150px' }}
								/>
								<h3 className="text-lg font-bold mb-4">Congratulations!</h3>
								<p className="mb-4">Your Deal has been successfully added.</p>
								<button
									className="bg-theme-yellow text-black font-bold py-2 px-4 rounded hover:bg-black hover:text-white"
									onClick={toggleDealModal}
								>
									Close
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default AddDeal;
