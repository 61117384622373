import React, { useState } from "react";
import Filter from "./Filter";
import AvailableRideList from "./AvailableRideList";

const UserView = ({ rides, ratings, onSubmitRating, onFetchRatings }) => {
  const [filteredRides, setFilteredRides] = useState(rides);

  return (
    <>
      <Filter rides={rides} setFilteredRides={setFilteredRides} />
      <AvailableRideList
        Rides={filteredRides}
        ratings={ratings}
        onSubmitRating={onSubmitRating}
        onFetchRatings={onFetchRatings}
      />
    </>
  );
};

export default UserView;
