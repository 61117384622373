import React from "react";
import Logo from "../../src/images/Logo.png";

const ComingSoon = () => {
  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-800">
        <div className="text-center space-y-8 -mt-80">
          <div className="relative w-32 h-32 mx-auto">
            <img
              src={Logo}
              alt="logo"
              className="w-full h-full object-contain animate-spin-slow"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-24 h-24 rounded-full border-4 border-yellow-500 animate-ping"></div>
            </div>
          </div>
          <h1 className="text-4xl sm:text-5xl font-bold text-yellow-500 animate-bounce">
            Coming Soon
          </h1>
          <div className="text-white text-lg sm:text-xl animate-fade-in">
            Get ready for an amazing surprise!
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
