import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Button } from '@mui/material';
import TogoLogo from '../../images/Logo.png';
import AvailableRide from '../../images/ride-hailing.png';
import BuySell from '../../images/shopping-bag.png';
import Food from '../../images/food-deal.png';
import Grocery from '../../images/food.png';
import './Home.css';

function PassengerHome({ onViewChange }) {
	useEffect(() => {
		Aos.init();
	}, []);

	const handleRidesClick = () => {
		onViewChange('UserView');
	};

	const handleDealsClick = () => {
		onViewChange('ViewDeals');
	};

	const handleFoodClick = () => {
		onViewChange('ComingSoon');
	};

	const handleGroceryClick = () => {
		onViewChange('ComingSoon');
	};

	return (
		<div className="bg-gradient-to-b from-gray-900 to-gray-600 min-h-screen flex flex-col justify-center items-center">
			<div>
				<img
					src={TogoLogo}
					alt="Logo"
					className="w-150 mx-auto pb-10"
				/>
			</div>
			<div className="flex justify-between space-x-4 ">
				<Button
					className="label-box w-[150px] h-[140px] backdrop-filter backdrop-blur-lg shadow-xl  transition-transform transform hover:scale-105"
					data-aos="slide-left"
					data-aos-duration="1000"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '13px',

						backgroundColor: 'rgb(61 73 100 / 40%)',
						backgroundColor: 'rgb(61 73 100 / 40%)',
					}}
					onClick={handleRidesClick}
				>
					<label htmlFor="driver">
						<img
							src={AvailableRide}
							alt="Driver"
							className="w-[70px] block object-center mx-auto rounded-xl"
						/>
						<span className="ml-2 text-lg font-11 text-white">Rides</span>
					</label>
				</Button>
				<Button
					className="label-box w-[150px] h-[140px] bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-full shadow-lg transition-transform transform hover:scale-105"
					data-aos="slide-right"
					data-aos-duration="1000"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '13px',
						backgroundColor: 'rgb(61 73 100 / 40%)',
					}}
					onClick={handleDealsClick}
				>
					<label htmlFor="driver">
						<img
							src={BuySell}
							alt="Driver"
							className="w-[70px] block object-center mx-auto rounded-xl"
						/>
						<span className="ml-2 text-lg font-11 text-white">Buy & Sell</span>
					</label>
				</Button>
			</div>

			<div className="flex justify-between space-x-4 mt-4">
				<Button
					className="label-box w-[150px] h-[140px] bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg transition-transform transform hover:scale-105"
					data-aos="slide-left"
					data-aos-duration="1000"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '13px',

						backgroundColor: 'rgb(61 73 100 / 40%)',
					}}
					onClick={handleFoodClick}
				>
					<label htmlFor="">
						<img
							src={Food}
							alt=""
							className="w-[70px] block object-center mx-auto rounded-xl"
						/>
						<span className="ml-2 text-lg font-11 text-white">Food</span>
					</label>
				</Button>
				<Button
					className="label-box w-[150px] h-[140px] bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg transition-transform transform hover:scale-105"
					data-aos="slide-right"
					data-aos-duration="1000"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '13px',

						backgroundColor: 'rgb(61 73 100 / 40%)',
					}}
					onClick={handleGroceryClick}
				>
					<label htmlFor="">
						<img
							src={Grocery}
							alt=""
							className="w-[70px] block object-center mx-auto rounded-xl"
						/>
						<span className="ml-2 text-lg font-11 text-white">Grocery</span>
					</label>
				</Button>
			</div>
		</div>
	);
}

export default PassengerHome;
