import React from 'react';
import WhatsAppIconBlack from '../../images/whatsapp-black.png';

const ViewDeals = ({ deals }) => {
	const editModule = (deal) => {
		console.log('Edit module for deal:', deal);
		// Your edit module logic here
	};

	console.log(deals);
	return (
		<div className="container mx-auto">
			{deals.map((deal, index) => {
				// Prepare the WhatsApp link with the country code
				const countryCode = '+92'; // Update this with your desired country code
				const phoneNumber = deal.deal_phone_number?.replace(/^0/, '') || ''; // Remove leading zero if present
				const waLink = `https://wa.me/${countryCode}${phoneNumber}`;

				return (
					<div
						className="border-gray-300 rounded-md  bg-theme-black grid mx-auto max-w-sm mt-5"
						key={index}
					>
						<div>
							<div className="ride-ui mx-auto max-w-sm mb-4">
								<div className="ride-imge">
									{Array.isArray(deal.deal_media) &&
										deal.deal_media.map((image, i) => (
											<img
												key={i}
												src={image.file}
												alt={`Deal Image ${i}`}
												className="h-48 w-[400px] object-cover"
											/>
										))}
								</div>
								<div className="callender-info-box flex justify-center items-center bg-black h-12">
									<h3 className="text-black font-bold">{deal.deal_title}</h3>
								</div>
								<div className="ride-info-box justify-center items-center bg-white rounded-2xl">
									<div className="grid grid-cols-2">
										<div className="p-2">
											<h3 className="text-black">Regular Price</h3>
											<p className="text-black">
												<del className="text-red-500">{deal.deal_regular_price} </del>
											</p>
										</div>
										<div className="p-2">
											<h3 className="text-black">Sale Price</h3>
											<p className="text-black">{deal.deal_sale_price}</p>
										</div>
									</div>
									<div className="grid grid-cols-1">
										<h2 className="text-sm mt-4 text-black font-medium">{deal.deal_details}</h2>
									</div>
								</div>

								<div className="rid-action-box mt-4 text-center">
									<button className="mx-auto">
										<img
											src={WhatsAppIconBlack}
											alt="WhatsApp icon"
										/>
										<span>
											{/* <a href={`https://wa.me/${phoneNumber}`}>Contact</a> */}
											<a href={waLink}>Contact</a>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ViewDeals;
