import React, { useState } from "react";
import { formatDate } from "../../services/helper";
import WhatsAppIconBlack from "../../images/whatsapp-black.png";
import RatingModal from "./RatingModal";
import "./style.css";
import { getData } from "../../services/http";

const AvailableRideList = ({
  Rides,
  ratings,
  onSubmitRating,
  onFetchRatings,
}) => {
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [currentRating, setCurrentRating] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const openRatingModal = (userId) => {
    setSelectedUserId(userId);
    setIsRatingModalOpen(true);
  };

  const closeRatingModal = () => {
    setIsRatingModalOpen(false);
    setCurrentRating(0);
    setSelectedUserId(null);
  };

  const handleRatingSubmit = async (ratingData) => {
    await onSubmitRating(ratingData);
    await onFetchRatings();
    closeRatingModal();
  };

  const calculateAverageRating = (rideId) => {
    if (!ratings || !Array.isArray(ratings)) {
      return 0;
    }

    const rideRatings = ratings.filter(
      (rating) => rating && rating.rideId === rideId
    );

    if (rideRatings.length === 0) return 0;

    // Calculate average rating
    const sum = rideRatings.reduce((acc, rating) => acc + rating.value, 0);
    return sum / rideRatings.length;
  };

  if (!Rides || Rides.length === 0) {
    return (
      <div className="">
        <p className="h-96 flex justify-center items-center text-yellow-400 text-2xl">
          No Rides Available
        </p>
      </div>
    );
  }

  return (
    <div>
      {Rides.map((ride, index) => {
        const formattedDateTime = formatDate(ride.ride_datetime);
        const date = new Date(formattedDateTime);
        const monthsOfYear = [
          "JANUARY",
          "FEBRUARY",
          "MARCH",
          "APRIL",
          "MAY",
          "JUNE",
          "JULY",
          "AUGUST",
          "SEPTEMBER",
          "OCTOBER",
          "NOVEMBER",
          "DECEMBER",
        ];
        const month = date.getMonth();
        const monthOfYear = monthsOfYear[month];

        const year = date.getFullYear();
        const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const day = date.getDay();
        const dayOfWeek = daysOfWeek[day];
        const timeOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
        const time = date.toLocaleTimeString(undefined, timeOptions);

        // Ensure phone number is in the correct international format
        let phoneNumber = ride.phone_number || "";
        if (phoneNumber.startsWith("0")) {
          phoneNumber = `92${phoneNumber.substring(1)}`;
        }

        // Calculate average rating for current ride
        const averageRating = calculateAverageRating(ride.id);

        return (
          <div className="ride-ui mx-auto max-w-sm mb-4" key={index}>
            <div className="ride-imge-overlay">
              <h2 className="text-white font-bold pl-3 pr-3">
                <i>
                  <span>{ride.ride_source}</span> →{" "}
                  <span>{ride.ride_destination}</span>
                </i>
              </h2>
            </div>
            <div className="ride-imge">
              {ride.ride_media?.map((image, i) => (
                <img
                  key={i}
                  src={image.file}
                  alt={`Uploaded Image ${i}`}
                  className="h-48 w-full object-cover rounded-2xl"
                />
              ))}
            </div>
            <div className="callender-info-box flex justify-center items-center bg-black">
              <div className="day">{dayOfWeek}</div>
              <div className="month bg-black text-white">
                {monthOfYear}, {year}
              </div>
              <div className="time">{time}</div>
            </div>
            <div className="ride-info-box justify-center items-center bg-white rounded-2xl">
              <div className="grid grid-cols-2">
                <div className="">
                  <h3>Seats</h3>
                  <p>{ride.ride_seats}</p>
                </div>
                <div className="">
                  <h3>Price</h3>
                  <p>{ride.ride_price}</p>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="p-2">
                  <h3>CAR MODEL</h3>
                  <p>{ride.ride_car_make}</p>
                </div>
                <div className="p-2">
                  <h3>Year</h3>
                  <p>{ride.ride_car_mode}</p>
                </div>
              </div>
            </div>
            <div className="rid-action-box mt-4 text-center">
              <button className="mx-auto ">
                <img src={WhatsAppIconBlack} alt="WhatsApp icon" />
                <span>
                  <a href={`https://wa.me/${phoneNumber}`}>Contact Rider</a>
                </span>
              </button>
            </div>
            <div className="rating-action-box flex mt-2 justify-between items-center">
              <div className="flex">
                {[...Array(5)].map((star, index) => (
                  <span
                    key={index}
                    className={`text-2xl cursor-pointer ${
                      currentRating > index
                        ? "text-yellow-500"
                        : "text-gray-300"
                    }`}
                    onClick={() => openRatingModal(ride.user_id)}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div>
                <h3>{ride.name}</h3>
                <p>Average Rating: {averageRating.toFixed(2)}</p>
              </div>
              <p className="inline-block ml-2">Rated</p>
            </div>
          </div>
        );
      })}
      <RatingModal
        isOpen={isRatingModalOpen}
        onRequestClose={closeRatingModal}
        rating={currentRating}
        setRating={setCurrentRating}
        userId={selectedUserId}
        onSubmit={handleRatingSubmit}
      />
    </div>
  );
};

export default AvailableRideList;
