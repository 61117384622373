import React, { useEffect, useState } from "react";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserView from "./Pages/Passengers/UserView";
import Register from "./Pages/Authentication/Register";
import Login from "./Pages/Authentication/Login";
import Header from "./Components/Header";
import { postRating, getRating } from "./services/http";

import {
  DoLogin,
  getRides,
  saveData,
  deleteRide,
  saveDeal,
  deleteDeal,
  getDeals,
  getData,
} from "./services/http";
import useLocalStorage from "./services/useLocalStorage";
import { getUserID, getUserRole } from "./services/auth";
import RiderList from "./Pages/Rider/RiderList";
import PassengerHome from "./Pages/Home/PassengerHome";
import ViewDeals from "./Pages/Deals/ViewDeals";
import MyDeal from "./Pages/Deals/MyDeal";
import ComingSoon from "./Components/ComingSoon";

function App() {
  const [view, setView] = useState("loading");
  const [User, setUser] = useLocalStorage("togo_user");
  const [Rides, setRides] = useLocalStorage("togo_rides", []);
  const [Deals, setDeals] = useLocalStorage("togo_deals", []);
  const [myDeals, setMyDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user_role = await getUserRole();
        const user_id = user_role === "driver" ? getUserID() : null;
        // const response = await getRides(user_id, user_role);
        const response = await getRides(user_id); // Pass the user_id parameter
        setRides(response.rides);
        handleViewByRole();
      } catch (error) {
        console.error("Error fetching rides:", error);
      }
    };

    fetchData();
  }, [setRides, User]);

  // fetching Deals
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const user_role = await getUserRole();
        const user_id = user_role === "driver" ? null : getUserID();
        // Fetch deals based on user_id
        const response = await getDeals(user_id);
        setMyDeals(response.deals);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };
    const fetchAllDeals = async () => {
      try {
        const user_id = null;
        const response = await getDeals(user_id);
        setDeals(response.deals);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchDeals();
    fetchAllDeals();
  }, [setDeals, User]);

  // useEffect(() => {
  //   fetchRatings(); // Fetch ratings
  // }, []);
  // const fetchRatings = async () => {
  //   try {
  //     const response = await getData(
  //       "ratingsPK",
  //       "ratingsSK",
  //       "ratingsBeginWith"
  //     ); // Adjust parameters as needed
  //     setRatings(response);
  //   } catch (error) {
  //     console.error("Error fetching ratings:", error);
  //   }
  // };
  useEffect(() => {
    fetchRatings();
  }, []);
  const fetchRatings = async () => {
    try {
      const userId = getUserID(); // Or however you get the current user's ID
      console.log(userId);
      const ratingsData = await getRating("UUID-1234-5678-90AB-CDEF");
      console.log(ratingsData);
      setRatings(Array.isArray(ratingsData) ? ratingsData : []);
    } catch (error) {
      console.error("Error fetching ratings:", error);
    }
  };

  // Function to submit a new rating
  const handleSubmitRating = async (ratingData) => {
    // const newData = { ...ratingData, user_id: "UUID-1234-5678-90AB-CDEF" };
    try {
      const response = await postRating(ratingData);

      if (response.success) {
        setRatings((prevRatings) => [...prevRatings, response.rating]);
        // fetchRatings(); // Re-fetch the ratings
        toast.success("Rating submitted successfully!", { autoClose: 3000 });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
      toast.error("Failed to submit rating");
    }
  };
  const handleViewByRole = async () => {
    if (User) {
      const user_role = await getUserRole();
      console.log(user_role);
      const view = user_role === "driver" ? "RiderList" : "UserView";
      setView(view);
    } else {
      setView("Login");
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleLogin = async (login_info) => {
    try {
      login_info.pin_code = Number(login_info.pin_code);
      const { success, message, user_info } = await DoLogin(login_info);
      //   console.log(success, message, user_info);
      if (success) {
        setUser(user_info);
        toast.success("Login successful!", { autoClose: 2000 });
        // window.location.reload();
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error(`Wrong Login Info: ${e.message}`);
    }
  };
  const handleVerifiedUser = (user_info) => {
    setUser(user_info);
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.removeItem("togo_user");
    window.location.reload();
  };

  const handleAddRide = async (ride) => {
    try {
      const objtest = { ...ride, user_id: User.user_id };
      const resp = await saveData(objtest);
      const { success, message, ride: new_ride } = resp;
      if (success) {
        const rides = [new_ride, ...Rides];
        setRides(rides);
        toast.success(message, { autoClose: 3000 });
      } else {
        toast.error(message);
      }
    } catch (e) {
      console.log(e.message);
      toast.error(e.message);
    }
  };

  const handleAddDeal = async (deal) => {
    try {
      const objtest = { ...deal, user_id: User.user_id };
      console.log(objtest);
      const resp = await saveDeal(objtest);
      const { success, message, deal: new_deal } = resp;
      if (success) {
        const deals = [new_deal, ...myDeals];
        setMyDeals(deals);
        toast.success(message, { autoClose: 3000 });
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleDeleteRide = async (rideId) => {
    try {
      await deleteRide(rideId);
      const updatedRides = Rides.filter((ride) => ride.ride_id !== rideId);
      setRides(updatedRides);
      toast.success("Ride deleted successfully", { autoClose: 3000 });
    } catch (error) {
      console.error("Error deleting ride:", error);
      toast.error("Failed to delete ride");
    }
  };

  const handleDeleteDeal = async (dealId) => {
    try {
      await deleteDeal(dealId);
      const updatedDeals = myDeals.filter((deal) => deal.deal_id !== dealId);
      setMyDeals(updatedDeals);
      toast.success("Deal deleted successfully", { autoClose: 3000 });
    } catch (error) {
      console.error("Error deleting deal:", error);
      toast.error("Failed to delete deal");
    }
  };

  // Update Seats Value
  const handleEditRide = async (item, ride) => {
    setLoading(true);
    try {
      const response = await saveData(ride);
      if (response.success) {
        const filteredRides = Rides.filter((r) => r.ride_id !== ride.ride_id);
        setRides([ride, ...filteredRides]);
      }
      toast.success("Ride Updated successfully", { autoClose: 3000 });
    } catch (error) {
      console.error("Error updating ride :", error);
    }
    setLoading(false);
  };

  const handleStatusChange = async (ride) => {
    try {
      ride.ride_status = !ride.ride_status;
      const resp = await saveData(ride);
      const { success, message } = resp;
      if (success) {
        const rides = [...Rides];
        const found = rides.find((r) => r.ride_id === ride.ride_id);
        const index = rides.indexOf(found);
        rides[index] = ride;
        setRides([...rides]);
        toast.success(message, { autoClose: 3000 });
      } else {
        toast.error(message);
      }
    } catch (e) {
      console.log(e.message);
      toast.error(e.message);
    }
  };

  // Deal status chnage
  const handleDealStatusChange = async (deal) => {
    try {
      deal.deal_isactive = !deal.deal_isactive;
      console.log(deal.deal_id);

      const resp = await saveDeal(deal);
      const { success, message } = resp;
      if (success) {
        const deals = [...myDeals];
        const found = deals.find((d) => d.deal_id === deal.deal_id);
        const index = deals.indexOf(found);
        deals[index] = deal;
        setMyDeals([...deals]);
        toast.success(message, { autoClose: 3000 });
      } else {
        toast.error(message);
      }
    } catch (e) {
      console.log(e.message);
      toast.error(e.message);
    }
  };

  const showAddRideButton =
    view !== "UserView" &&
    view !== "MyDeal" &&
    view !== "ViewDeals" &&
    view !== "ComingSoon";
  const showHomeButton = view !== "RiderList";
  const showAddDealButton =
    view !== "RiderList" &&
    view !== "ViewDeals" &&
    view !== "UserView" &&
    view !== "ComingSoon";
  const showMyDealButton =
    view !== "RiderList" &&
    view !== "UserView" &&
    view !== "MyDeal" &&
    view !== "ComingSoon";

  // Filter rides & Deals where status is true
  const activeRides = Rides.filter((ride) => ride.ride_status);
  const activeDeals = Deals.filter((deal) => deal.deal_isactive);

  return (
    <>
      <div className="App">
        {view === "Register" && (
          <Register
            onViewChange={handleViewChange}
            onVerifiedUser={handleVerifiedUser}
          />
        )}
        {view === "Login" && (
          <Login onLogin={handleLogin} onViewChange={handleViewChange} />
        )}
        {view === "PassengerHome" && (
          <PassengerHome onViewChange={handleViewChange} />
        )}
        {(view === "UserView" ||
          view === "RiderList" ||
          view === "ViewDeals" ||
          view === "ComingSoon" ||
          view === "MyDeal") && (
          <>
            <Header
              onLogout={handleLogout}
              onViewChange={handleViewChange}
              showAddRideButton={showAddRideButton}
              showHomeButton={showHomeButton}
              showAddDealButton={showAddDealButton}
              showMyDealButton={showMyDealButton}
              onAddRide={handleAddRide}
              onAddDeal={handleAddDeal}
            />
          </>
        )}
        {view === "UserView" && (
          <UserView
            rides={activeRides}
            ratings={ratings}
            onSubmitRating={handleSubmitRating}
            onFetchRatings={fetchRatings}
          />
        )}
        {view === "RiderList" && (
          <RiderList
            Rides={Rides}
            onDeleteRide={handleDeleteRide}
            onStatusChange={handleStatusChange}
            onEditRide={handleEditRide}
          />
        )}
        {view === "ViewDeals" && (
          <ViewDeals
            onViewChange={handleViewChange}
            // deals={Deals}
            deals={activeDeals}
            myDeals={myDeals}
          />
        )}
        {view === "MyDeal" && (
          <MyDeal
            onViewChange={handleViewChange}
            myDeals={myDeals}
            User={User}
            Deals={Deals}
            onDeleteDeal={handleDeleteDeal}
            onDealStatusChange={handleDealStatusChange}
          />
        )}
        {view === "ComingSoon" && (
          <ComingSoon onViewChange={handleViewChange} />
        )}
      </div>
      <ToastContainer />
    </>
  );
}
export default App;
