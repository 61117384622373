import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { formatDate } from "../../services/helper";
import Delete from "../../images/delete.png";
import "./Rider.css";
import Loader from "react-js-loader";
import EditAppIconBlack from "../../images/editing.png";

const RiderList = ({ Rides, onDeleteRide, onStatusChange, onEditRide }) => {
  const [activeStatus, setActiveStatus] = useState({});
  const [modal, setModal] = useState(false);
  const [editRide, setEditRide] = useState({});
  const [updatedSeats, setUpdatedSeats] = useState(null);

  const handleSeatsChange = (seats) => {
    setEditRide({ ...editRide, ride_seats: seats });
  };

  const handleDelete = async (rideId) => {
    console.log(rideId);
    try {
      const confirmation = window.confirm(
        "Are you sure you want to delete this ride?"
      );
      if (!confirmation) {
        return;
      }
      await onDeleteRide(rideId);
    } catch (error) {
      console.error("Error deleting ride:", error);
    }
  };
  const editRideModal = () => {
    setModal(!modal);
  };
  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  useEffect(() => {
    if (Rides && Rides.length > 0) {
      const initialStatus = Rides.reduce((acc, ride) => {
        acc[ride.ride_id] = true;
        return acc;
      }, {});
      setActiveStatus(initialStatus);
    }
  }, [Rides]);

  if (!Rides) {
    return null;
  }

  if (Rides.length === 0) {
    return (
      <div>
        <h2 className="mt-5 font-semibold text-white">No added rides.</h2>
      </div>
    );
  }
  const editModule = (ride) => {
    setEditRide(ride);
    editRideModal();
  };
  return (
    <div>
      {Rides.map((ride, index) => {
        // Extract date and time here for each ride
        const formattedDateTime = formatDate(ride.ride_datetime);
        const date = new Date(formattedDateTime);
        const dateString = date.toDateString();
        const timeString = date.toLocaleTimeString();
        const rideId = ride.ride_id;
        const monthsOfYear = [
          "JANUARY",
          "FEBRUARY",
          "MARCH",
          "APRIL",
          "MAY",
          "JUNE",
          "JULY",
          "AUGUST",
          "SEPTEMBER",
          "OCTOBER",
          "NOVEMBER",
          "DECEMBER",
        ];
        const month = date.getMonth() + 1;
        const monthOfYear = monthsOfYear[month];

        const year = date.getFullYear();
        const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const day = date.getDay();
        const dayOfWeek = daysOfWeek[day];
        const timeOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
        const time = date.toLocaleTimeString(undefined, timeOptions);

        return (
          <div
            className=" border-gray-300 rounded-md bg-theme-black grid  mx-auto max-w-sm  mt-5"
            key={index}
          >
            <div>
              <div className="ride-ui mx-auto max-w-sm mb-4" key={index}>
                <div className="ride-imge-overlay">
                  <h2 className="text-white font-bold pl-3 pr-3">
                    <i>
                      <span>{ride.ride_source}</span> →{" "}
                      <span>{ride.ride_destination}</span>
                    </i>
                  </h2>
                </div>
                <div className="ride-imge">
                  {ride.ride_media?.map((image, i) => (
                    <img
                      key={i}
                      src={image.file}
                      alt={`Uploaded Image ${i}`}
                      className="h-48 w-full object-cover rounded-2xl"
                    />
                  ))}
                </div>
                <div className="callender-info-box flex justify-center items-center bg-black">
                  <div className="day" style={{ fontSize: "12px" }}>
                    {dayOfWeek}
                  </div>
                  <div
                    className="month bg-black text-white"
                    style={{ fontSize: "12px" }}
                  >
                    {monthOfYear} {date.getDate()}, {year}
                  </div>
                  <div className="time" style={{ fontSize: "12px" }}>
                    {time}
                  </div>
                </div>

                <div className="ride-info-box justify-center items-center bg-white rounded-2xl">
                  <div className="grid grid-cols-2">
                    <div className="">
                      <h3>Seats</h3>
                      <p>{ride.ride_seats}</p>
                    </div>
                    <div className="">
                      <h3>Price</h3>
                      <p>{ride.ride_price}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="p-2">
                      <h3>CAR MODAL</h3>
                      <p>{ride.ride_car_make}</p>
                    </div>
                    <div className="p-2">
                      <h3>Year</h3>
                      <p>{ride.ride_car_mode}</p>
                    </div>
                  </div>
                </div>
                <div className="rid-action-box mt-4 text-center">
                  <button className="mx-auto " onClick={() => editModule(ride)}>
                    <img src={EditAppIconBlack} alt="What's App icon" />
                    <span>Edit Ride</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {modal && (
        <div className="modal">
          <div onClick={editRideModal} className="overlay"></div>
          <button
            className="close-modal bg-red-600 hover:bg-black"
            onClick={editRideModal}
          >
            CLOSE
          </button>
          <div className="modal-content">
            <div className="flex flex-col items-center justify-between w-full">
              <div className="flex items-center justify-between w-full">
                <div className="mb-2 mr-4">
                  <label className="inline-flex items-center cursor-pointer mt-5">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={editRide.ride_status}
                      onChange={() => onStatusChange(editRide)}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400"></div>
                    <span className="ms-3 text-sm font-medium text-white">
                      {activeStatus[editRide.ride_id] ? "Active" : "Inactive"}
                    </span>
                  </label>
                </div>
                <button
                  className="bg-red-600 hover:bg-black text-white font-semibold py-2 px-4 rounded w-45"
                  onClick={() => handleDelete(editRide.ride_id)}
                >
                  <img src={Delete} alt="Delete" className="w-5 h-5 " />
                </button>
              </div>
            </div>
            <div>
              <select
                id="cars"
                name="cars"
                className="w-full border border-gray-300 rounded-md p-2 bg-white text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                onChange={(e) => handleSeatsChange(e.target.value)}
              >
                <option value="">Update Number of Seats</option>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((seats) => (
                  <option key={seats} value={seats}>
                    {seats}
                  </option>
                ))}
              </select>

              <button
                className="mt-3 w-full bg-green-600"
                onClick={() => {
                  onEditRide("seats", editRide);
                  setModal(false);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RiderList;
