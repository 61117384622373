import React, { useState, useEffect } from "react";
import Verify from "./Verify";
import driver from "../../images/drive-icon.png";
import passenger from "../../images/passenger.png";
import Logo from "../../images/Logo.png";
import "./Login.css";
import RiderList from "../Rider/RiderList";
import UserView from "../Passengers/UserView";
import Aos from "aos";
import "aos/dist/aos.css";
import { DoSignup, verifyPinCode } from "../../services/http";
import { toast } from "react-toastify";

function Register({ onViewChange, onVerifiedUser }) {
  useEffect(() => {
    Aos.init({
      // duration: 2000
    });
  });

  const [formData, setFormData] = useState({
    full_name: "",
    phone_number: "",
    user_role: "",
  });
  const [step, setStep] = useState(1); // Manage the current step
  const [showVerifyBlock, setShowVerifyBlock] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOptionChange = (e) => {
    setFormData({
      ...formData,
      user_role: e.target.value,
    });
  };

  const toggleVerifyBlock = () => {
    setShowVerifyBlock(!showVerifyBlock);
  };

  const handleVerify = async (verificationCode) => {
    try {
      const pinCodeString = verificationCode.join(""); // Merge array elements into a single string
      const pinCodeNumber = parseInt(pinCodeString); // Convert the string to a number

      const userInfo = await verifyPinCode(
        formData.phone_number,
        pinCodeNumber
      );

      if (userInfo.user_id) {
        onVerifiedUser(userInfo);
      }
      toast.success("Your account is verified!", {
        autoClose: 2000,
      });
    } catch (error) {
      toast.error("Verification failed. Please try again.");
    }
  };

  const handleLoginClick = () => {
    onViewChange("Login");
  };

  const handleSignup = async () => {
    try {
      const { success, message, code } = await DoSignup(formData);
      if (success) {
        // setUser(user_info);
        // window.location.reload();
        setStep(step + 1);
        toast.success("Please check your WhatsApp and enter code.", {
          autoClose: 2000,
        });
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error(`Invalid Code: ${e.message}`);
    }
  };

  return (
    <div className="bg-theme-black min-h-screen flex flex-col justify-center items-center">
      <div className="bg-theme-yellow w-full"></div>

      <div className="w-full max-w-md mt-8 bg-black p-8 rounded shadow-md">
        <div className="flex items-center justify-center mb-6 text-2xl font-semibold ">
          <img
            src={Logo}
            alt="TOGO Logo"
            className="w-40"
            data-aos="flip-right"
            data-aos-duration="2000"
          />
        </div>

        {/* Step 1: User inputs options, name, and phone number */}
        {step === 1 && (
          <>
            {/* <div className="flex justify-between space-x-4">
							<span
								className="w-1/2"
								data-aos="slide-right"
								data-aos-duration="1000"
							>
								<input
									type="radio"
									id="passenger"
									name="user_role"
									value="passenger"
									checked={formData.user_role === 'passenger'}
									onChange={handleOptionChange}
									className="form-radio h-5 w-5 text-indigo-600 radio-check hide"
								/>
								<label
									htmlFor="passenger"
									className="w-full cursor-pointer text-black mb-2 bg-white text-center block rounded-lg pt-4 pb-4"
								>
									<img
										src={passenger}
										alt="Passenger"
										className="w-16 block object-center mx-auto"
									/>
									<span className="ml-2 text-lg font-11">I'm a Passenger</span>
								</label>
							</span>
							<span
								className="w-1/2"
								data-aos="slide-left"
								data-aos-duration="1000"
							>
								<input
									type="radio"
									id="driver"
									name="user_role"
									value="driver"
									checked={formData.user_role === 'driver'}
									onChange={handleOptionChange}
									className="form-radio h-5 w-5 text-indigo-600 radio-check hide"
								/>
								<label
									htmlFor="driver"
									className="w-full cursor-pointer text-black mb-2 bg-white text-center block rounded-lg inline-block pt-4 pb-4"
								>
									<img
										src={driver}
										alt="Driver"
										className="w-16 block object-center mx-auto"
									/>
									<span className="ml-2 text-lg font-11">I'm a Driver</span>
								</label>
							</span>
						</div> */}

            <div className=" mt-8">
              <input
                type="text"
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-black placeholder-white"
                placeholder="Full Name"
              />
            </div>

            <div className="mb-6 mt-6">
              <input
                type="number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-black placeholder-white"
                placeholder="Phone Number"
              />
            </div>
            <div className="mb-6 mt-4">
              <select
                name="signupas"
                id="signupas"
                class="custom-select shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-black placeholder-white mt-0"
                value={formData.user_role}
                onChange={handleOptionChange}
              >
                <option value="passenger">Sign up as a Passenger</option>
                <option value="driver">Sign up as a Driver</option>
              </select>
            </div>

            <div>
              <button
                className="bg-theme-yellow hover:bg-white hover:text-black text-black font-bold py-2 px-4 rounded"
                onClick={handleSignup}
              >
                Next
              </button>
            </div>
          </>
        )}

        {/* Step 2: Verification */}
        {step === 2 && (
          <div>
            <Verify onVerify={handleVerify} />
          </div>
        )}

        {/* Step 3: Proceed to RiderView or UserView */}
        {step === 3 && (
          <div>
            {formData.user_role === "passenger" ? <UserView /> : <RiderList />}
          </div>
        )}

        <p className="text-sm font-light text-gray-500 dark:text-gray-400 mt-2">
          Already have an account?{" "}
          <button
            type="button"
            onClick={handleLoginClick}
            className="font-medium text-green-600 hover:underline dark:text-primary-500"
          >
            Login
          </button>
        </p>
      </div>
    </div>
  );
}

export default Register;
