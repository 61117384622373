import React, { useState } from "react";
import cityData from "../../Data/city.json";
import modelYears from "../../Data/years.json";
import remove from "../../images/remove.png";
import SearchIcon from "../../images/search.png";

const cities = cityData.cities;
const yearOptions = modelYears.years;

const Filter = ({ rides, setFilteredRides }) => {
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [carModel, setCarModel] = useState("");
  const [date, setDate] = useState("");
  const [showFilters, setShowFilters] = useState(false); // State to toggle filter display

  const handleSourceChange = (e) => {
    const newSource = e.target.value;
    setSource(newSource);
    filterRides(newSource, destination, carModel, date);
  };

  const handleDestinationChange = (e) => {
    const newDestination = e.target.value;
    setDestination(newDestination);
    filterRides(source, newDestination, carModel, date);
  };

  const handleCarModelChange = (e) => {
    const newCarModel = e.target.value;
    setCarModel(newCarModel);
    filterRides(source, destination, newCarModel, date);
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDate(newDate);
    filterRides(source, destination, carModel, newDate);
  };

  const filterRides = (source, destination, carModel, date) => {
    let filteredRides = rides;
    if (source) {
      filteredRides = filteredRides.filter((ride) =>
        ride.ride_source.toLowerCase().includes(source.toLowerCase())
      );
    }
    if (destination) {
      filteredRides = filteredRides.filter((ride) =>
        ride.ride_destination.toLowerCase().includes(destination.toLowerCase())
      );
    }
    if (carModel) {
      filteredRides = filteredRides.filter((ride) =>
        ride.ride_car_mode.toLowerCase().includes(carModel.toLowerCase())
      );
    }
    if (date) {
      filteredRides = filteredRides.filter((ride) => {
        const rideDate = new Date(ride.ride_datetime);
        const selectedDate = new Date(date);
        return (
          rideDate.getDate() === selectedDate.getDate() &&
          rideDate.getMonth() === selectedDate.getMonth() &&
          rideDate.getFullYear() === selectedDate.getFullYear()
        );
      });
    }
    setFilteredRides(filteredRides);
  };

  return (
    <div className="flex justify-center items-center mt-5">
      <div
        id="filterBar"
        className="rounded-lg w-full sm:w-1/2 mx-auto bg-theme-black"
      >
        <div className="flex justify-center mb-2">
          <button
            className="flex items-center bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? "Filters" : "Filters"}
            <img src={SearchIcon} alt="Search" className="ml-2 w-6 h-6" />
          </button>
        </div>

        {/* Filter options */}
        {showFilters && (
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mx-auto mb-4 p-3 rounded-lg">
            <div className="relative">
              <select
                value={source}
                onChange={handleSourceChange}
                className="w-full border border-gray-300 p-2 rounded-md"
              >
                <option value="">Source</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              {source && (
                <img
                  src={remove}
                  alt="Remove"
                  className="absolute bottom-8 right-0 mr-[-6px] p-0 bg-red-500 rounded-full cursor-pointer"
                  style={{ width: "18px", height: "18px" }}
                  onClick={() => {
                    setSource("");
                    filterRides("", destination, carModel, date);
                  }}
                />
              )}
            </div>
            <div className="relative">
              <select
                value={destination}
                onChange={handleDestinationChange}
                className="w-full border border-gray-300 p-2 rounded-md"
              >
                <option value="">Destination</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              {destination && (
                <img
                  src={remove}
                  alt="Remove"
                  className="absolute bottom-8 right-0 mr-[-6px] p-0 bg-red-500 rounded-full cursor-pointer"
                  style={{ width: "18px", height: "18px" }}
                  onClick={() => {
                    setDestination("");
                    filterRides(source, "", carModel, date);
                  }}
                />
              )}
            </div>
            <div className="relative">
              <select
                value={carModel}
                onChange={handleCarModelChange}
                className="w-full border border-gray-300 p-2 rounded-md"
              >
                <option value="">Car Model</option>
                {yearOptions.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {carModel && (
                <img
                  src={remove}
                  alt="Remove"
                  className="absolute bottom-8 right-0 mr-[-6px] p-0 bg-red-500 rounded-full cursor-pointer"
                  style={{ width: "18px", height: "18px" }}
                  onClick={() => {
                    setCarModel("");
                    filterRides(source, destination, "", date);
                  }}
                />
              )}
            </div>
            <div className="relative">
              <input
                type="date"
                id="filter"
                value={date}
                onChange={handleDateChange}
                className="w-full border border-gray-300 p-2 h-10 rounded-lg focus:outline-none focus:border-blue-500"
              />
              {date && (
                <img
                  src={remove}
                  alt="Remove"
                  className="absolute bottom-8 right-0 mr-[-6px] p-0 bg-red-500 rounded-full cursor-pointer"
                  style={{ width: "18px", height: "18px" }}
                  onClick={() => {
                    setDate("");
                    filterRides(source, destination, carModel, "");
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;
